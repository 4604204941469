<ng-template vdrDialogTitle>Selecteer product status</ng-template>
<vdr-form-field>
    <select name="state" [(ngModel)]="selectedState">
        <option *ngFor="let state of availableStates" [value]="state">
            {{ 'state.' + state | translate }}
        </option>
    </select>
</vdr-form-field>
<ng-template vdrDialogButtons>
    <button type="submit" *ngIf="cancellable" (click)="cancel()" class="btn btn-secondary">
        {{ 'common.cancel' | translate }}
    </button>
    <button type="submit" (click)="select()" class="btn btn-primary" [disabled]="!selectedState">
        {{ 'common.confirm' | translate }}
    </button>
</ng-template>

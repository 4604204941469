<vdr-page-block>
    <vdr-action-bar>
        <vdr-ab-left>
            <div class="agromaat-btn-group agromaat-btn-outline-primary mode-select" *ngIf="selectedDevice">
                <div *ngFor="let option of modeOptions" class="radio agromaat-btn">
                    <input
                        type="radio"
                        name="mode"
                        [id]="option.id"
                        [title]="option.title"
                        [value]="option.value"
                        [checked]="mode === option.value"
                    />
                    <label [for]="option.id" (click)="setMode(option.value)">
                        <cds-icon
                            *ngIf="option.iconShape === 'arrow'"
                            [attr.direction]="option.iconDirection"
                            [attr.shape]="option.iconShape"
                        ></cds-icon>
                        <clr-icon
                            *ngIf="option.iconShape !== 'arrow'"
                            [attr.shape]="option.iconShape"
                        ></clr-icon>
                    </label>
                </div>
            </div>
        </vdr-ab-left>
        <vdr-ab-right>
            <div class="flex clr-row items-center">
                <button
                    [hidden]="!('SuperAdmin' | hasPermission) || !selectedDevice"
                    [disabled]="(devices$ | async)?.isSyncing"
                    class="btn btn-secondary"
                    (click)="syncJofemarVisionEsPlus()"
                >
                    <span *ngIf="(devices$ | async)?.isSyncing">
                        <clr-spinner clrInline />
                    </span>
                    Synchroniseer
                </button>
                &nbsp;
                <button
                    [hidden]="!('SuperAdmin' | hasPermission) || !selectedDevice"
                    class="btn btn-danger"
                    (click)="resetToFactorySettings()"
                >
                    Herstel naar fabrieksinstellingen
                </button>
                <vdr-dropdown *ngIf="(devices$ | async)?.length > 1">
                    <button type="button" class="btn button button-select-device" vdrDropdownTrigger>
                        {{
                            selectedDevice
                                ? ('agromaat.agroflex.agroflex' | translate) +
                                  ' ' +
                                  selectedDevice.machineAddress.port
                                : 'Selecteer een AgroFlex'
                        }}
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <vdr-dropdown-menu vdrPosition="bottom-right">
                        <button
                            type="button"
                            class="language-option"
                            *ngFor="let device of devices$ | async"
                            (click)="selectDevice(device)"
                            vdrDropdownItem
                        >
                            <span
                                >{{ 'agromaat.agroflex.agroflex' | translate }}
                                {{ device.machineAddress.port }}</span
                            >
                        </button>
                    </vdr-dropdown-menu>
                </vdr-dropdown>
            </div>
        </vdr-ab-right>
    </vdr-action-bar>
</vdr-page-block>

<vdr-page-detail-layout>
    <vdr-page-block>
        <clr-accordion class="accordion" [clrAccordionMultiPanel]="true">
            <ng-container *ngFor="let item of items$ | async; trackBy: trackByJofemarVisionEsPlusTrayId">
                <clr-accordion-panel
                    [clrAccordionPanelOpen]="panels[item.id] || true"
                    (clrAccordionPanelOpenChange)="togglePanel(item.id)"
                >
                    <clr-accordion-title>Rij {{ item.sequence }}</clr-accordion-title>
                    <clr-accordion-content class="accordion-content">
                        <div class="accordion-grid">
                            <div
                                *ngFor="
                                    let jofemarVisionEsPlusChannel of item.jofemarVisionEsPlusChannels;
                                    trackBy: trackByJofemarVisionEsPlusChannelId
                                "
                                class="accordion-grid-item"
                                [ngClass]="{ 'in-editmode': mode !== 'home' }"
                                (click)="mode !== 'home' ? update(jofemarVisionEsPlusChannel) : null"
                            >
                                <span class="in-stock">{{ jofemarVisionEsPlusChannel.inStock }}</span>
                                <div
                                    *ngIf="
                                        jofemarVisionEsPlusChannel.productVariant as productVariant;
                                        else noProduct
                                    "
                                >
                                    <img
                                        *ngIf="
                                            productVariant.featuredAsset ||
                                                productVariant.product.featuredAsset as asset;
                                            else noImage
                                        "
                                        [src]="asset | assetPreview: 'thumb'"
                                        alt="{{ jofemarVisionEsPlusChannel.channelAlias }} - {{
                                            jofemarVisionEsPlusChannel.productVariant.name
                                        }}"
                                    />
                                </div>
                                <ng-template #noProduct>
                                    <div class="fallback-product-div">
                                        <p>Nog geen product gekoppeld</p>
                                    </div>
                                </ng-template>
                                <ng-template #noImage>
                                    <div class="fallback-image-div">
                                        <p>
                                            {{ jofemarVisionEsPlusChannel.productVariant.name }}
                                        </p>
                                    </div>
                                </ng-template>
                                <span class="channel-alias">
                                    {{ jofemarVisionEsPlusChannel.channelAlias }}
                                    <div
                                        *ngIf="loadingStates[jofemarVisionEsPlusChannel.id]"
                                        class="loading-indicator"
                                    >
                                        <clr-spinner clrInline />
                                    </div>
                                </span>
                            </div>
                        </div>
                    </clr-accordion-content>
                </clr-accordion-panel>
            </ng-container>
        </clr-accordion>
    </vdr-page-block>
</vdr-page-detail-layout>

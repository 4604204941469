import gql from 'graphql-tag';
import { PRODUCT_CELL_FRAGMENT } from '../../common/fragments.graphql';

export const GET_PRODUCT_CELLS = gql`
    query GetProductCells($options: ProductCellListOptions) {
        productCells(options: $options) {
            items {
                ...ProductCell
            }
            totalItems
        }
    }
    ${PRODUCT_CELL_FRAGMENT}
`;

export const SUBSCRIPTION_PRODUCT_CELL_STATE_UPDATED = gql`
    subscription productCellStateUpdated {
        productCellStateUpdated {
            id
            state
        }
    }
`;

export const BULK_UPDATE_PRODUCT_CELL_PRODUCT_VARIANT_FOR_PRODUCT_CELLS_OVERVIEW = gql`
    mutation bulkUpdateProductCellProductVariantForProductCellsOverview(
        $input: BulkUpdateProductCellProductVariantInput!
    ) {
        bulkUpdateProductCellProductVariant(input: $input) {
            ...ProductCell
        }
    }
    ${PRODUCT_CELL_FRAGMENT}
`;

export const BULK_OPEN_PRODUCT_CELL_FOR_PRODUCT_CELLS_OVERVIEW = gql`
    mutation BulkOpenProductCellForProductCellsOverview($input: BulkOpenProductCellInput!) {
        bulkOpenProductCell(input: $input) {
            ...ProductCell
        }
    }
    ${PRODUCT_CELL_FRAGMENT}
`;

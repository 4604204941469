<ng-template vdrDialogTitle>{{ 'agromaat.bulk.change-stock' | translate }}</ng-template>
<form clrForm>
    <clr-input-container class="expand">
        <label>{{ 'catalog.stock-on-hand' | translate }}</label>
        <input clrInput name="stock" [(ngModel)]="stock" type="number" min="0" step="1" />
    </clr-input-container>
</form>

<ng-template vdrDialogButtons>
    <button type="button" class="btn" (click)="cancel()">
        {{ 'common.cancel' | translate }}
    </button>
    <button type="submit" (click)="submit()" class="btn btn-primary">
        {{ 'common.confirm' | translate }}
    </button>
</ng-template>

<ng-template vdrDialogTitle>
    {{ 'catalog.select-product-variant' | translate }}
</ng-template>
<ng-select
    [items]="results$ | async"
    [typeahead]="searchTerm$"
    appendTo="body"
    (change)="select($event)"
    #selectComponent
>
    <ng-template ng-option-tmp let-item="item">
        <img
            *ngIf="item.featuredAsset || item.product.featuredAsset as asset"
            [src]="asset | assetPreview: 32"
        />
        {{ item.name }}
    </ng-template>
</ng-select>
<ng-template vdrDialogButtons>
    <button type="submit" *ngIf="cancellable" (click)="cancel()" class="btn btn-secondary">
        {{ 'common.cancel' | translate }}
    </button>
    <button type="submit" (click)="select()" class="btn btn-primary" [disabled]="!selectedState">
        {{ 'common.confirm' | translate }}
    </button>
</ng-template>

import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentNode } from 'graphql/language/ast';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { HttpLink } from 'apollo-angular/http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getAppConfig, getServerLocation, LocalStorageService } from '@vendure/admin-ui/core';
import { InMemoryCache, split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';

@Injectable()
export class GqlSubscriptionService {
    constructor(
        private httpLink: HttpLink,
        private localStorageService: LocalStorageService,
        private apollo: Apollo,
    ) {
        const { adminApiPath, tokenMethod, channelTokenKey } = getAppConfig();
        const serverLocation = getServerLocation();
        const http = this.httpLink.create({
            uri: `${serverLocation}/${adminApiPath}`,
        });

        const ws = new GraphQLWsLink(
            createClient({
                url: `${serverLocation}/subscription-api`,
                shouldRetry: () => true,
                connectionParams: () => {
                    const headers: Record<string, string> = {};
                    const channelToken = this.localStorageService.get('activeChannelToken');
                    if (channelToken) {
                        headers[channelTokenKey ?? 'vendure-token'] = channelToken;
                    }
                    if (tokenMethod === 'bearer') {
                        const authToken = this.localStorageService.get('authToken');
                        if (authToken) {
                            headers.authorization = `Bearer ${authToken}`;
                        }
                    }
                    headers['Apollo-Require-Preflight'] = 'true';
                    // Return an object with your connection initialization payload
                    return {
                        headers,
                    };
                },
            }),
        );

        const link = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            ws,
            http,
        );
        this.apollo.createNamed('subscription', {
            link,
            cache: new InMemoryCache(),
        });
    }

    /**
     * Performs a GraphQL subscription
     */
    subscribe<T, V extends Record<string, any> = Record<string, any>>(
        query: DocumentNode | TypedDocumentNode<T, V>,
        variables?: V,
    ): Observable<T> {
        return this.apollo
            .use('subscription')
            .subscribe<T, V>({
                query,
                variables,
            })
            .pipe(
                map(result => result.data as T),
                catchError(err => {
                    console.error('Subscription error:', err);
                    return EMPTY;
                }),
            );
    }
}
